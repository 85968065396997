import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import Grid from '@mui/material/Grid2'; // Updated to Grid2 from Material-UI
import { Button, Typography } from '@mui/material';

// Function to replace Bootstrap classes with Material-UI Grid2 components
const htmlConvert = ({ htmlContent }) => {
  if (!htmlContent || typeof htmlContent !== 'string') {
    return null; // Return null if content is not a valid string
  }

  const replaceColClasses = (node) => {
    if (node.name === 'div' && node.attribs?.class?.includes('col-')) {
      const classList = node.attribs.class.split(' ');
      const gridSizes = {};

      classList.forEach((cls) => {
        if (cls.startsWith('col-')) {
          const [_, size, value] = cls.split('-');
          if (size === 'md') gridSizes.md = parseInt(value, 10);
          if (size === 'sm') gridSizes.sm = parseInt(value, 10);
          if (size === 'xs') gridSizes.xs = parseInt(value, 10);
        }
      });

      return (
        <Grid size={gridSizes}>
          {domToReact(node.children)}
        </Grid>
      );
    }

    if (node.name === 'a' && node.attribs?.class?.includes('btn')) {
      return (
        <Button
          component="a"
          variant="contained"
          color="primary"
          href={node.attribs.href}
          sx={{ backgroundColor: '#2c52a3', color: 'white' }}
        >
          {domToReact(node.children)}
        </Button>
      );
    }

    if (node.name === 'h2') {
      return (
        <Typography variant="h4" component="h2">
          {domToReact(node.children)}
        </Typography>
      );
    }

    if (node.name === 'p') {
      return (
        <Typography variant="body1">
          {domToReact(node.children)}
        </Typography>
      );
    }
  };

  return parse(htmlContent, {
    replace: (node) => {
      if (node.name === 'div' && node.attribs?.class) {
        if (node.attribs.class.includes('row')) {
          return (
            <Grid container spacing={3} alignItems="center">
              {domToReact(node.children, { replace: replaceColClasses })}
            </Grid>
          );
        }

        if (node.attribs.class.includes('container-fluid') || node.attribs.class.includes('container')) {
          return (
            <Grid container spacing={3} alignItems="center" sx={{ marginY: 2 }}>
              {domToReact(node.children, { replace: replaceColClasses })}
            </Grid>
          );
        }
      }
    },
  });
};

export default htmlConvert;
