import React from 'react';
import { DataProvider } from '../cms/cmsProvider.js';
import { ServicesProvider } from '../service/serviceProvider.js';
import { SliderProvider } from '../slider/sliderProvider.js';
import { EventsProvider } from '../event/eventProvider.js';
import { ClubsProvider } from '../clubs/clubProvider.js'; // Import ClubsProvider

const RootProvider = ({ children }) => (
  <DataProvider>
    <EventsProvider>
      <SliderProvider>
        <ServicesProvider>
          <ClubsProvider> {/* Wrap children with ClubsProvider */}
            {children}
          </ClubsProvider>
        </ServicesProvider>
      </SliderProvider>
    </EventsProvider>
  </DataProvider>
);

export default RootProvider;
