// hooks/useHeader.js
import { useState, useEffect } from 'react';
import {getMentors} from './mentorsApi';

const useMentors = () => {
  const [mentors, setMentors] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMentors();
        setMentors(data || []); 
      } catch (err) {
        console.error('Error fetching mentors:', err); 
        setError('Failed to fetch '); 
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  return { mentors, loading, error };
};

export default useMentors;