import React, { createContext, useContext, useState, useEffect } from 'react';
import { getEvents } from '../api/apiService'; // Adjust path as needed

const EventsContext = createContext();

export const useEventsContext = () => useContext(EventsContext);

export const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if events data is already in localStorage or sessionStorage
    const storedEvents = localStorage.getItem('eventsData');
    
    if (storedEvents) {
      setEvents(JSON.parse(storedEvents)); // Load data from localStorage
      setLoading(false); // Skip loading state if data is available in cache
    } else {
      const fetchEventsData = async () => {
        try {
          const data = await getEvents();
          setEvents(data || []);
          localStorage.setItem('eventsData', JSON.stringify(data)); // Store data in localStorage
        } catch (err) {
          setError('Failed to fetch events');
        } finally {
          setLoading(false);
        }
      };
      fetchEventsData();
    }
  }, []);

  return (
    <EventsContext.Provider value={{ events, loading, error }}>
      {children}
    </EventsContext.Provider>
  );
};
