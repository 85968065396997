import * as React from 'react';
import Images from '../../../../images/images';
import Grid from '@mui/material/Grid2';
import { Button, Box, Container, Typography } from '@mui/material';

export default function AboutKdl() {
  return (
    <Box component="section" className="home-about">
      <Container maxWidth="xl" >
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid size={{md:5}}>
          <Box component='img'
          src={Images.Side1}
          sx={{ width: '100%' }} 
          ></Box>
        </Grid>
        <Grid size={{md:7}}>
          <Typography component="h2" variant='h2'>About Kalam Dream Labs</Typography>
          <Typography component="body" variant='body' sx={{mt:5}}>  It’s a hub for passionate people to gather and bring a change in society. Our platform acts as a cross functional, cross domain networking zone to build products, launch startups, upscale companies, solve global issues and follow ones passion. The beauty of this network lies in it being social as well as commercial platform.
                <br />
                <br />
                We as human beings need people to achieve our dreams. Dreaming is easy but bringing dreams to reality is difficult. To achieve this one needs a lot of mentoring, guidance and expertise to save time and money. Kalam Dream labs mentoring team is focusing to create an impact on the lives of the people who want to bring a positive change to the society, we help in finding the right set of people to achieve the dreams together.</Typography>
        
                <Button variant="contained" sx={{mt:5}}>
                read more
              </Button>
        </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
