// services/apiService.js
import axios from 'axios';

// Base URL from environment variable
const API_URL = process.env.REACT_APP_API_BASE_URL;

// Generalized function to fetch data from any endpoint
export const fetchData = async (endpoint) => {
  try {
    const response = await axios.get(`${API_URL}${endpoint}`);
    return response.data; // Keep this for general use
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

// Specific functions that use the generalized fetchData function with their specific endpoints
export const getMenu = () => fetchData('cms');
export const getSlider = () => fetchData('slider');
export const getServices = () => fetchData('services');
export const getClubs = () => fetchData('clubs');
export const getSocial = () => fetchData('social');
export const getContact = () => fetchData('contact');
export const getFooter = () => fetchData('footer');

// Updated getEvents function to return only the events array
export const getEvents = async () => {
  const response = await fetchData('events');
  return response.data || []; // Return the array directly, defaulting to an empty array if null
};
