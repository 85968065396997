import { useState, useEffect } from 'react';
import { getSocial } from '../../appSettings/api/apiService';

const useSocial = () => {
  const [social, setSocial] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSocial();
        // Ensure `data.socialsetting` is an array
        setSocial(Array.isArray(data.socialsetting) ? data.socialsetting : []);
      } catch (err) {
        console.error('Error fetching social:', err);
        setError('Failed to fetch social links');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { social, loading, error };
};

export default useSocial;
