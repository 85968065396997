import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import '../../css/responsive.css'

export default function MobileNav({
  anchorElNav,
  setAnchorElNav,
  handleNavigate,
  transformedData, // Receive transformed data as prop
}) {
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          className="menuButton"
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          {transformedData.map((page) => (
            <React.Fragment key={page.slug} className="mobileMenu">
              <MenuItem onClick={() => handleNavigate(`/${page.slug}`)}>
                <Typography textAlign="center">{page.title}</Typography>
              </MenuItem>
              {page.subPages && (
                <Box sx={{ pl: 3 }}>
                  {page.subPages.map((subPage) => (
                    <MenuItem
                      key={subPage.slug}
                      onClick={() => handleNavigate(`/${subPage.slug}`)}
                    >
                      <Typography textAlign="center">{subPage.title}</Typography>
                    </MenuItem>
                  ))}
                </Box>
              )}
            </React.Fragment>
          ))}
        </Menu>
      </Box>
    </>
  );
}
