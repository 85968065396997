import React from "react";
import {
  Container,
  Typography,
  Link,
  Box,
  CardMedia,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./footer.css";
import Social from "../social/social.js";
import ServicesLinks from "./services-links.js";
import FooterContact from "./footer-contact.js";
import useFooter from "./footerApi.js";
import { SITE_IMAGES_URL } from "../../appSettings/imageConstants.js";

const Footer = () => {
  const { footer, loading, error } = useFooter();

  // Extract footer data
  const footerLogo = footer.find((item) => item.slug === "footer-logo")?.value;
 
  const footerLinksHTML = footer.find((item) => item.slug === "footer-links")?.value;
  const copyrightText = footer.find((item) => item.slug === "copyright-text")?.value;

  // Handle loading and error states
  if (loading) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error}</Box>;

  return (
    <Box component="footer" className="footer">
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {/* Logo and Social Media */}
          <Grid
            item
            size={{ xs: 12, md: 3 }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {footerLogo && (
            <Link href="/">
            <CardMedia
              component="img"
              src={`${SITE_IMAGES_URL}${footerLogo}`} // Use curly braces to wrap the template literal
              alt="Footer Logo"
              className="footer-logo"
            />
          </Link>
            )}
            <Social />
          </Grid>

          {/* Quick Links */}
          <Grid item size={{ xs: 12, md: 3 }}>
            <Typography variant="h6" className="footer-heading">
              Quick Links
            </Typography>
            <Box component="div" className="footer-list" dangerouslySetInnerHTML={{ __html: footerLinksHTML }} />
          </Grid>

          {/* Services */}
          <ServicesLinks />

          {/* Contact */}
          <FooterContact />
        </Grid>
      </Container>

      <Box className="footer-bottom">
        <Container maxWidth="xl">
          <Typography align="center">
            {copyrightText ? (
              <Box dangerouslySetInnerHTML={{ __html: copyrightText }} />
            ) : (
              "All Rights Reserved."
            )}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
