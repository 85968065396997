import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Tagline = () => {
    return (
        <Box component="section" align="center">
      <Container maxWidth="xl">
                <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
                    <Box component="span" sx={{ display: 'block', color: '#2c52a3' }}>
                        Dream, Dream, Dream...
                    </Box>
                    <Box component="span" sx={{ padding: '0.5rem' }}>
                        Dreams transform into thoughts and thoughts result in actions!
                    </Box>
                </Typography>
            </Container>
        </Box>
    );
};

export default Tagline;
