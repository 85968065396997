// Import images at the top of the module
import InnerBanner from '../images/inner-banner.png';
import NoImage from '../images/noimage.jpg';

// Define the base URL for images using environment variables
export const IMAGE_BASE_URL = `${process.env.REACT_APP_IMAGE_BASE_URL}inner-pages/`;
export const IMAGE_SERVICES_URL = `${process.env.REACT_APP_IMAGE_BASE_URL}services/`;
export const IMAGE_SLIDER_URL = `${process.env.REACT_APP_IMAGE_BASE_URL}slider/`;
export const IMAGE_EVENTS_URL = `${process.env.REACT_APP_IMAGE_BASE_URL}event/`;
export const IMAGE_TEAMS_URL = `${process.env.REACT_APP_IMAGE_BASE_URL}teams/`;
export const SITE_IMAGES_URL = `${process.env.REACT_APP_IMAGE_BASE_URL}site-images/`;



// Export an object containing image references
export const images = {
  InnerBanner, NoImage
  // Add any other images here
};
