

const Images = {
    Banner1 : require('../images/banners/kdl_banner_1.png'),
    Banner2 : require('../images/banners/kdl_banner_2.png'),
    Banner3 : require('../images/banners/kdl_banner_3.png'),
    Logo : require('./logo/logo1.png'),
    Side1 : require('./kdl_images/kdl_side_img.jpg'),
    Whyandhow :require('./kdl_images/whyandhow.jpg'),
    Internship : require('./services/services_internship.png'),
    Consulting : require('./services/services_consulting.png'),
    Funding : require('./services/services_funding.png'),
    Mentoring : require('./services/services_mentoring.png'),
    Patenting : require('./services/services_patenting.png'),
    Research : require('./services/services_research.png'),
    StartUp : require('./services/services_startup.png'),
    Training : require('./services/services_training.png'),
    Volunteering : require('./services/services_volunteering.png'),
    Mission: require('./missionVission/mission.png'),
    Vision: require('./missionVission/vision.png'),
    Internship_image1 : require('./internship/intern_image1.png'),
    Internship_image2 : require('./internship/intern_image2.jpg'),
    Internship_banner : require('./internship/internship_banner.png'),
    RegistrationSuccess : require('./request-form.svg'),
    CloudyImg : require('./cloudy.svg')


};

export default Images;