import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    Container,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Snackbar,
    Alert,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import InnerLayout from '../../layouts/InnerLayout';

function InternshipForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        college_name: '',
        passed_out_year: '',
        branch: '',
        plans_to_do: '',
        hobbies: [],
        skills: [],
        age: '',
        gender: '',
        are_you_a_vizag_volunteer: '',
        intrested_to_learn: '',
        previous_experience: '',
        reason_for_unavailability: '',
        available_timings: '',
        acknowledgement: '',
        resume: null,
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
    });

    const navigate = useNavigate();
    useEffect(() => {
        document.body.classList.add('internship-page');
        return () => {
            document.body.classList.remove('internship-page');
        };
    }, []);

    const hobbiesOptions = ['Reading', 'Writing', 'Music', 'Sports', 'Traveling', 'Photography'];
    const skillsOptions = ['JavaScript', 'Python', 'React', 'CSS', 'HTML', 'Node.js', 'Laravel', 'Flutter', 'Nextjs'];

    const handleCheckboxChange = (e, field) => {
        const { name, checked } = e.target;
        setFormData((prevData) => {
            const updatedArray = checked
                ? [...prevData[field], name]
                : prevData[field].filter((item) => item !== name);
            return { ...prevData, [field]: updatedArray };
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, resume: e.target.files[0] });
    };

    const validateForm = () => {
        const requiredFields = [
            { field: 'name', label: 'Name' },
            { field: 'email', label: 'Email' },
            { field: 'mobile', label: 'Mobile' },
            { field: 'acknowledgement', label: 'Acknowledgement', check: () => formData.acknowledgement === 1 },
            { field: 'available_timings', label: 'Available Timings' },
            { field: 'intrested_to_learn', label: 'Interested to Learn' },
            { field: 'branch', label: 'Branch' },
            { field: 'college_name', label: 'College Name' },
            { field: 'gender', label: 'Gender' },
            { field: 'age', label: 'Age' },
            { field: 'resume', label: 'Resume', check: () => !!formData.resume },
        ];

        for (const { field, label, check } of requiredFields) {
            if ((!formData[field] && formData[field] !== 0) || (check && !check())) {
                setSnackbar({ open: true, message: `Please fill out the ${label} field.` });
                return false;
            }
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Run client-side validation first
        if (!validateForm()) {
            return; // Stop if validation fails
        }

        // Destructure email and mobile from formData for uniqueness check
        const { email, mobile } = formData;

        try {
            // Check if email or mobile already exists
            const checkResponse = await axios.post('http://127.0.0.1:8000/api/check-user', { email, mobile });


            // Log response for debugging
            console.log("Check response:", checkResponse.data);

            if (checkResponse.data.emailExists || checkResponse.data.mobileExists) {
                // If email or mobile exists, show an error message
                setSnackbar({
                    open: true,
                    message: 'Email or Mobile number already exists. Please use a different one.',
                    severity: 'error',
                });
                return; // Stop submission if duplicate found
            }
        } catch (error) {
            console.error('Error checking email or mobile number:', error);
            setSnackbar({
                open: true,
                message: 'An error occurred while checking email or mobile number.',
                severity: 'error',
            });
            return; // Stop if there's an error in uniqueness check
        }

        // Proceed to form submission only if the uniqueness check passes
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === 'resume' && formData[key]) {
                data.append(key, formData[key]); // Append resume file
            } else if (Array.isArray(formData[key])) {
                formData[key].forEach((item) => data.append(key, item)); // Handle arrays if any
            } else {
                data.append(key, formData[key]); // Append other fields
            }
        });
        

        try {
            // Submit the form
            await axios.post('http://127.0.0.1:8000/api/submit-internship-form', data, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            navigate('/registration-success'); // Redirect on success
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            setSnackbar({
                open: true,
                message: 'An error occurred while submitting the form.',
                severity: 'error',
            });
        }
    };


    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 40 }, (_, k) => currentYear - k);
    const ageOptions = Array.from({ length: 101 }, (_, k) => k);
    const interestedOptions = [
        'Digital Marketing',
        'Graphic Designing',
        'Video Editing',
        'Web Development',
        'Cloud Computing',
        'Mobile App Development',
        'Full Stack Development',
    ];

    return (
        <InnerLayout>
            <Box component="section">
                <Container maxWidth="xl">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <Typography variant="h3" component="h1" align='center' sx={{ mb: 5 }}>Career Based Internship Form</Typography>
                        <Typography variant="h5" component="h6" align='center' sx={{ mb: 5 }}>Note: Please read all fields carefully before filling out the form.</Typography>

                        <Grid container spacing={2}>
                            <Grid item size={{ md: 6, }} className="fullWidth">
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}

                                />
                            </Grid>
                            <Grid item size={{ md: 6 }} className="fullWidth">
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="email"

                                />
                            </Grid>

                            <Grid item size={{ md: 6 }} className="fullWidth">
                                <TextField
                                    fullWidth
                                    label="Mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}

                                    inputProps={{
                                        maxLength: 10,
                                        minLength: 10,
                                    }}
                                />
                            </Grid>

                            <Grid item size={{ md: 6 }} className="fullWidth">
                                <FormControl fullWidth >
                                    <InputLabel>Are you available for an interview at any time?</InputLabel>
                                    <Select
                                        name="available_timings"
                                        value={formData.available_timings}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Select an Option
                                        </MenuItem>
                                        <MenuItem value="yes">Yes</MenuItem>
                                        <MenuItem value="no">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {formData.available_timings === 'no' && (
                                <Grid item size={{ md: 6 }} className="fullWidth">
                                    <TextField
                                        fullWidth
                                        label="Reason for Unavailability"
                                        name="reason_for_unavailability"
                                        value={formData.reason_for_unavailability}
                                        onChange={handleChange}

                                    />
                                </Grid>
                            )}



                            <Grid item size={{ md: 4 }} className="halfWidth">
                                <FormControl fullWidth >
                                    <InputLabel>Age</InputLabel>
                                    <Select
                                        name="age"
                                        value={formData.age}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Select Age
                                        </MenuItem>
                                        {ageOptions.map((age) => (
                                            <MenuItem key={age} value={age}>
                                                {age}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item size={{ md: 4 }} className="halfWidth">
                                <FormControl fullWidth >
                                    <InputLabel>Gender</InputLabel>
                                    <Select
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Select Gender
                                        </MenuItem>
                                        <MenuItem value="male">Male</MenuItem>
                                        <MenuItem value="female">Female</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item size={{ md: 4 }} className="fullWidth">
                                <FormControl fullWidth >
                                    <InputLabel>Are You A Vizag Volunteer?</InputLabel>
                                    <Select
                                        name="are_you_a_vizag_volunteer"
                                        value={formData.are_you_a_vizag_volunteer}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Select an Option
                                        </MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>

                            <Grid item size={{ md: 4 }} className="fullWidth">
                                <FormControl fullWidth >
                                    <Select
                                        name="passed_out_year"
                                        value={formData.passed_out_year}
                                        onChange={handleChange}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Year of Passing
                                        </MenuItem>
                                        {years.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>




                            <Grid item size={{ md: 4 }} className="fullWidth">
                                <TextField
                                    fullWidth
                                    label="College Name"
                                    name="college_name"
                                    value={formData.college_name}
                                    onChange={handleChange}

                                />
                            </Grid>

                            <Grid item size={{ md: 4 }} className="fullWidth">
                                <TextField
                                    fullWidth
                                    label="Branch"
                                    name="branch"
                                    value={formData.branch}
                                    onChange={handleChange}

                                />
                            </Grid>


                            <Grid item size={{ md: 6 }} className="fullWidth">
                                <FormControl fullWidth >
                                    <InputLabel>Interested to Learn</InputLabel>
                                    <Select
                                        name="intrested_to_learn"
                                        value={formData.intrested_to_learn}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="" disabled>
                                            Select Interest
                                        </MenuItem>
                                        {interestedOptions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item size={{ md: 6 }} className="fullWidth">
                                <FormControl fullWidth>
                                    <InputLabel>Previous Experience</InputLabel>
                                    <Select
                                        label="Previous Experience"
                                        name="previous_experience"
                                        value={formData.previous_experience}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="yes">Yes</MenuItem>
                                        <MenuItem value="no">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>



                            {/* <Grid item size={{ md: 12 }} className="fullWidth">
                                <TextField
                                    fullWidth
                                    label="Plans to do"
                                    name="plans_to_do"
                                    value={formData.plans_to_do}
                                    onChange={handleChange}
                                />
                            </Grid> */}




                            <Grid item size={{ md: 12 }} className="fullWidth">
                                <TextField
                                    fullWidth
                                    label="Address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    multiline
                                    rows={3}

                                />
                            </Grid>
                            <Grid item md={6}>
                                <FormControl component="fieldset">
                                    <Typography variant="h6">Hobbies</Typography>
                                    <Grid container spacing={1}>
                                        {hobbiesOptions.map((hobby) => (
                                            <Grid item xs={6} key={hobby}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={hobby}
                                                            checked={formData.hobbies.includes(hobby)}
                                                            onChange={(e) => handleCheckboxChange(e, 'hobbies')}
                                                        />
                                                    }
                                                    label={hobby}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormControl>
                            </Grid>

                            <Grid item md={6}>
                                <FormControl component="fieldset">
                                    <Typography variant="h6">Skills</Typography>
                                    <Grid container spacing={1}>
                                        {skillsOptions.map((skill) => (
                                            <Grid item xs={6} key={skill}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={skill}
                                                            checked={formData.skills.includes(skill)}
                                                            onChange={(e) => handleCheckboxChange(e, 'skills')}
                                                        />
                                                    }
                                                    label={skill}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormControl>
                            </Grid>

                            <Grid item md={12}>
                                <Typography variant="subtitle1">Note:</Typography>
                                <List component="ol">
                                    <ListItem component="li">
                                        <ListItemText primary="The interview date and time will be adjusted according to the hiring process." />
                                    </ListItem>
                                    <ListItem component="li">
                                        <ListItemText primary="Please bring your resume to the interview." />
                                    </ListItem>
                                    <ListItem component="li">
                                        <ListItemText primary="The interview will include both technical and HR-related questions." />
                                    </ListItem>
                                    <ListItem component="li">
                                        <ListItemText primary="We will reach out to you via WhatsApp for communication." />
                                    </ListItem>
                                    <ListItem component="li">
                                        <ListItemText primary="Please wear formals while coming to the interview." />
                                    </ListItem>
                                </List>
                            </Grid>




                            <Grid item size={{ md: 12 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="acknowledgement"
                                            checked={formData.acknowledgement === 1}
                                            onChange={(e) =>
                                                setFormData({ ...formData, acknowledgement: e.target.checked ? 1 : 0 })
                                            }
                                        />
                                    }
                                    label="I hereby confirm that all the information provided above is true. I commit to responsibly fulfilling the deliverables assigned to me and will actively participate in any training activities as required. The selection of interns is purely determined by the mentors at Kalam Dream Labs."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    accept="application/pdf, application/msword"
                                    style={{ display: 'none' }}
                                    id="resume-upload"
                                    type="file"
                                    onChange={handleFileChange}

                                />
                                <label htmlFor="resume-upload">
                                    <Button variant="contained" color="primary" component="span">
                                        Upload Resume
                                    </Button>
                                    {formData.resume && (
                                        <Typography variant="body2">{formData.resume.name}</Typography>
                                    )}
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={3000}
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="error">
                            {snackbar.message}
                        </Alert>
                    </Snackbar>

                </Container>
            </Box>
        </InnerLayout>
    );
}

export default InternshipForm;