import React from 'react'
import Grid from "@mui/material/Grid2";
import useServices from "../../appSettings/service/servicehook.js"; 
import {
    Typography,
    Link,
    List,
    ListItem,
  } from "@mui/material";

export default function ServicesLinks() {
    const { service, loading, error } = useServices(); 
  return (
    <Grid item size={{ xs: 12, md: 3 }}>
    <Typography variant="h6" className="footer-heading">
      Services
    </Typography>

    {loading && <Typography>Loading services...</Typography>}
    {error && (
      <Typography color="error">Failed to load services.</Typography>
    )}
    <List className="list-arrow half-list">
      {!loading &&
        !error &&
        service.map((item) => (
          <ListItem key={item.id}>
            <Link
              href={`/services/${item.slug}`}
              underline="hover"
              sx={{ color: "white" }}
            >
              {item.title}
            </Link>
          </ListItem>
        ))}
    </List>
  </Grid>
  )
}
