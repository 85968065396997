import React from "react";
import { CircularProgress, Box, Alert, Typography } from "@mui/material";
import useContact from "./contactApi";

const ContactDetails = () => {
  const { contact, loading, error } = useContact();

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const contactInfo = {
    address: "",
    phone: "",
    email: "",
  };

  if (contact.length > 0) {
    contact.forEach(({ slug, value }) => {
      
      if (slug === "address") contactInfo.address = value;


      if (
        slug === "primary-phone-number" ||
        slug === "secondary-phone-number"
      ) {
        if (!contactInfo.phone) contactInfo.phone = value;
      }


      if (slug === "primary-email" || slug === "secondary-email") {
        if (!contactInfo.email) contactInfo.email = value;
      }


    });
  }

  return contactInfo;
};

export default ContactDetails;
