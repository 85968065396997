import React from "react";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../components/innerBanner/innerBanner";
import {
  Box,
  Container,
  Card,
  CardMedia,
  CardActions,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import InnerBannerImage from "../../images/inner-banner.png";
import { IMAGE_EVENTS_URL, images } from "../../appSettings/imageConstants.js";
import { useNavigate } from "react-router-dom";
import { useEventsContext } from "../../appSettings/event/eventProvider.js"; // Use the events context

function Events() {
  const navigate = useNavigate();
  const { events, loading, error } = useEventsContext(); // Use the context to get the events data

  if (loading) return <div>Loading the events...</div>;
  if (error) return <div>{error}</div>;

  return (
    <InnerLayout>
      <InnerBanner
        bannerImage={InnerBannerImage}
        title="Events"
        tagline="Come and Join our Events"
      />
      <Box component="section" sx={{ padding: "2rem 0" }}>
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            {Array.isArray(events) && events.length > 0 ? (
              events.map((item) => (
                <Grid item size={{ xs: 12, sm: 6, md: 4 }} key={item.id}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ height: 240 }}
                      src={
                        item.event_banner
                          ? `${IMAGE_EVENTS_URL}${item.event_banner}`
                          : images.NoImage
                      }
                      title={item.name || "Event Image"}
                    />
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.name}
                      </Typography>
                      {(item.start_date_and_time === 1 ||
                        item.start_date_and_time) && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            mb: 2,
                          }}
                        >
                          {item.start_date_and_time && (
                            <Typography className="mute-text">
                              Start Date: {item.start_date_and_time}
                            </Typography>
                          )}
                          {item.end_date_and_time && (
                            <Typography className="mute-text">
                              End Date: {item.end_date_and_time}
                            </Typography>
                          )}
                        </Box>
                      )}
                      {item.type && (
                        <Typography className="mute-text">
                          Mode of Event: {item.type}
                        </Typography>
                      )}
                      <Typography variant="body2" color="textSecondary">
                        {item.short_description && (
                          <Box
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: item.short_description,
                            }}
                          />
                        )}
                      </Typography>
                    </CardContent>
                    {(item.registration_button === 1 ||
                      item.long_description) && (
                      <CardActions sx={{ mt: "auto", px: 3, pb: 3 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          {item.long_description && (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => navigate(`/events/${item.slug}`)}
                            >
                              Read More
                            </Button>
                          )}
                          {item.registration_button === 1 && (
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() => navigate(`/register/${item.slug}`)}
                            >
                              Register
                            </Button>
                          )}
                        </Box>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))
            ) : (
              <div>No events found.</div>
            )}
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default Events;
