import React from "react";
import { useParams } from "react-router-dom";
import { useClubsContext } from "../../appSettings/clubs/clubProvider.js"; // Use the context
import InnerBanner from "../../components/innerBanner/innerBanner.js";
import InnerLayout from "../../layouts/InnerLayout.js";
import { Box, Container } from "@mui/material";
import htmlConvert from "../../appSettings/htmlConvert.js"; // Import htmlConvert
import InnerBannerImage from "../../images/inner-banner.png";

function ClubsDetails() {
  const { slug } = useParams();
  const { clubs } = useClubsContext(); // Use the context to get the clubs data
  const ourClub = clubs.find((item) => item.slug === slug); // Find the specific club based on the slug

  if (!ourClub) return <div>Club not found.</div>;

  return (
    <InnerLayout>
      <InnerBanner
        bannerImage={InnerBannerImage}
        title="Club Details"
        tagline=""
      />
      <Box component="section" py={4}>
        <Container maxWidth="xl">
          {/* Use htmlConvert component to display the club description */}
          {htmlConvert({ htmlContent: ourClub.description })}
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default ClubsDetails;
