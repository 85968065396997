import React from "react";
import Grid from "@mui/material/Grid2";
import { Typography, Link, List, ListItem, Box } from "@mui/material";
import { LocationOn, Phone, Email } from "@mui/icons-material";
import ContactDetails from "../contact/contact-details";
import useFooter from "./footerApi.js"; // Import the useFooter hook

function FooterContact() {
  const { address, phone, email } = ContactDetails();
  const { footer } = useFooter(); // Use the hook to fetch footer data

  // Get the footer text
  const footerText = footer.find((item) => item.slug === "footer-text")?.value;

  // Create an array of contact items
  const contactItems = [
    {
      label: "Address",
      value: address,
      icon: <LocationOn sx={{ mr: 1 }} />,
      href: `https://maps.app.goo.gl/${address}`,
    },
    {
      label: "Phone",
      value: phone,
      icon: <Phone sx={{ mr: 1 }} />,
      href: `tel:${phone}`,
    },
    {
      label: "Email",
      value: email,
      icon: <Email sx={{ mr: 1 }} />,
      href: `mailto:${email}`,
    },
  ];

  return (
    <Grid item size={{ xs: 12, md: 3 }}>
      <Typography variant="h6" className="footer-heading">
        Contact
      </Typography>

      {/* Render the footer text if available */}
      <Box
        component="div"
        className="footer-contact-description"
        dangerouslySetInnerHTML={{ __html: footerText }}
      />

      <List className="icon-list">
        {contactItems.map((item, index) =>
          item.value ? ( // Only render the item if a value is provided
            <ListItem key={index}>
              <Link href={item.href} target="_blank">
                {item.icon}
                {item.value}
              </Link>
            </ListItem>
          ) : null
        )}
      </List>
    </Grid>
  );
}

export default FooterContact;
