import * as React from "react";
import "../header/header.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Container,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/logo/logo1.png";
import MobileNav from "../header/mobileNav.js";
import { useDataContext } from "../../appSettings/cms/cmsProvider.js";
import { transformMenuData } from "../../appSettings/menuTransform.js"; // Import the utility

function Header() {
  const { data, loading, error } = useDataContext();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [activeSubMenu, setActiveSubMenu] = React.useState(null);

  const handleNavigate = (pageLink) => {
    navigate(pageLink);
    setAnchorElNav(null);
  };

  const handleSubMenuHover = (pageSlug) => {
    setActiveSubMenu(pageSlug);
  };

  const transformedData = transformMenuData(data || []); // Transform the data

  return (
    <AppBar position="static" sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo */}
          <Link to="/" className="main-logo">
            <img
              src={Logo}
              alt="Logo"
              style={{ height: "100px", marginRight: "10px" }}
            />
          </Link>

          {/* Mobile Navigation */}
          <MobileNav
            anchorElNav={anchorElNav}
            setAnchorElNav={setAnchorElNav}
            handleNavigate={handleNavigate}
            transformedData={transformedData} // Pass transformed data to mobile menu
          />

          {/* Desktop Menu */}
          <Box
            className="mainNav"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {/* Static links */}
            <Box className="navItem" sx={{ position: "relative" }}>
              <Button
                className="navLink"
                onClick={() => handleNavigate("/")}
                sx={{ my: 2, mx: 2, color: "black", display: "block" }}
              >
                Home
              </Button>
            </Box>

            <Box className="navItem" sx={{ position: "relative" }}>
              <Button
                className="navLink"
                onClick={() => handleNavigate("/services")}
                sx={{ my: 2, mx: 2, color: "black", display: "block" }}
              >
                Services
              </Button>
            </Box>

            {/* Dynamic pages from transformed data */}
            {!loading && !error && transformedData.length > 0 ? (
              transformedData.map((page) => (
                <Box
                  className="navItem"
                  key={page.slug}
                  onMouseEnter={() => handleSubMenuHover(page.slug)}
                  onMouseLeave={() => setActiveSubMenu(null)}
                  sx={{ position: "relative" }}
                >
                  <Button
                    className="navLink"
                    onClick={() => handleNavigate(`/${page.slug}`)}
                    sx={{ my: 2, mx: 2, color: "black", display: "block" }}
                  >
                    {page.title}
                    {page.subPages && page.subPages.length > 0 && (
                      <ArrowDropDownIcon sx={{ marginLeft: "5px" }} />
                    )}
                  </Button>

                  {/* Submenu */}
                  {activeSubMenu === page.slug && page.subPages.length > 0 && (
                    <Box className="subnav">
                      {page.subPages.map((subPage) => (
                        <Button
                          key={subPage.slug}
                          onClick={() => handleNavigate(`/${subPage.slug}`)}
                          sx={{ my: 1, color: "black", display: "block" }}
                        >
                          {subPage.title}
                        </Button>
                      ))}
                    </Box>
                  )}
                </Box>
              ))
            ) : loading ? (
              <Typography>Loading...</Typography>
            ) : error ? (
              <Typography>Error loading data</Typography>
            ) : (
              <Typography>No data available</Typography>
            )}

            {/* Additional Static links */}
            <Box className="navItem" sx={{ position: "relative" }}>
              <Button
                className="navLink"
                onClick={() => handleNavigate("/events")}
                sx={{ my: 2, mx: 2, color: "black", display: "block" }}
              >
                Events
              </Button>
            </Box>

            <Box className="navItem" sx={{ position: "relative" }}>
              <Button
                className="navLink"
                onClick={() => handleNavigate("/clubs")}
                sx={{ my: 2, mx: 2, color: "black", display: "block" }}
              >
                Clubs
              </Button>
            </Box>

            <Box className="navItem" sx={{ position: "relative" }}>
              <Button
                className="navLink"
                onClick={() => handleNavigate("/contact")}
                sx={{ my: 2, mx: 2, color: "black", display: "block" }}
              >
                Contact
              </Button>
            </Box>
          </Box>

          {/* Registration Button */}
          <Link className="registration-link" to="/internship-registration">
            <Button type="button" variant="contained" color="primary">
              Registration
            </Button>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
