import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Container, Typography } from '@mui/material';

export default function ServiceAccordion() {
  const [expanded, setExpanded] = React.useState('panel1'); // Default to the first accordion

  const handleChange = (panel) => (event, isExpanded) => {
    // If the current panel is already expanded, collapse it; otherwise, expand it
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box component='section'>
<Container maxWidth="xl" >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography >
          What types of businesses does Kalam Dream Labs work with?
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We work with startups and established businesses across various industries, helping them secure funding for growth and expansion.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography >How does Kalam Dream Labs match businesses with investors?</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We leverage our extensive network of investors and venture capitalists to match businesses with the right investors based on their industry, growth stage, and funding needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
     
      </Container>
      </Box>
  );
}
