import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Images from '../../../../images/images.js';
import './whyandhow.css'
import { Container } from '@mui/material';

export default function Whyandhow() {
  return (
    <Paper component='section' className="whyandhow">
      <Container maxWidth="xl" >
      <Grid container spacing={2}>
        {/* Image Section */}
        <Grid item size={{xs:12, sm:6}} className="grid-item">
          <ButtonBase sx={{ width: '100%', height: '100%' }}>
            <img
              alt="complex"
              src={Images.Whyandhow}
              className="whyandhow-image-wrapper"
            />
          </ButtonBase>
        </Grid>

        {/* Text Section */}
        <Grid item size={{ xs: 12, md: 6 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography
                gutterBottom
                variant="h3"
                component="h2"
                className="typography-heading"
              >
                Why And How
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                className="typography-body"
              >
                <br />
                Kalam Dream Labs is an establishment in remembrance and honour of Dr. APJ Abdul Kalam. Our inspiration and devotion towards Sir APJ Abdul Kalam made us take this step and help in creating a change in the community. It all started with a single man, the founder and visionary Mr. Satish Karnatakapu who built a strong foundation with a sincere dedicated team, decoding many problems arising within the enthusiast.
                <br />
                <br />
                Kalam Dream Labs fosters innovation by providing unrestricted access to resources and stakeholders, allowing for creative problem-solving. This culture empowers individuals with autonomy, enhancing their sense of ownership and responsibility. The open office environment boosts collaboration and speeds up decision-making by eliminating hierarchical barriers. It also creates a supportive atmosphere, encouraging mentorship and the sharing of expertise, allowing experienced members to guide new joinees and welcome fresh perspectives.
                <br />
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                className="button-read-more"
              >
                Read more <ChevronRightIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </Paper>
  );
}
