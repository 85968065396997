
import React from 'react'
import Aboutkdl from './home_page_components/About_Kdl/aboutKdl.js'
import VisionMissionCard from './visionAndMission/visionmissioncard.js'
import Whyandhow from './home_page_components/WhyAndHow/whyandhow.js'
import ServicesGrid from './service/service.js'
import TestimonialsSection from './testimonials/testimonials.js'
import Tagline from './tagline/tagline.js'
import MainLayout from '../../layouts/mainLayout.js'
import HeroSlider from './hero-section/hero-slider.js'

function Home() {
    return (
      <>
      <MainLayout>
      <HeroSlider/>
      <Aboutkdl/>
      <VisionMissionCard/>
      <Whyandhow/>
      <ServicesGrid/> 
      <TestimonialsSection/>
      <Tagline/>
      </MainLayout>
      </>
    )
  }
  
  export default Home