import React from 'react'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'

function MainLayout({ children }) { // Destructure children from props
  return (
    <>
      <Header/>
      {children} {/* Render children */}
      <Footer/>
    </>
  )
}

export default MainLayout