import Images from '../images/images.js'

const Data = {
  settings: ["Profile", "Account", "Dashboard", "Logout"],
  pages: [
    { Home: [], pageLink: '/' },
    {
      Services: [
        [{ page: "Internship" }, { pageLink: '/internship' }],
        [{ page: "Mentoring" }, { pageLink: '/mentoring' }],
        [{ page: "Volunteering" }, { pageLink: '/volunteering' }],
        [{ page: "Training" }, { pageLink: '/training' }],
        [{ page: "Consulting" }, { pageLink: '/consulting' }],
        [{ page: "StartUp" }, { pageLink: '/startup' }],
        [{ page: "Patenting" }, { pageLink: '/patenting' }],
        [{ page: "Research" }, { pageLink: '/research' }],
        [{ page: "Funding" }, { pageLink: '/funding' }],
      ],
    },
    { About: [[{ page: "Founder" }, { pageLink: '/founder' }], [{ page: "Our Mentors" }, { pageLink: '/ourmentors' }], [{ page: "Journey" }, { pageLink: '/journey' }]], },
    { Events: [], pageLink: '/events' },
    { Clubs: [], pageLink: '/clubs' },
    { Contact: [], pageLink: '/contact' },
  ],

  FooterQuickLinks: ["Home", "Contact", "Terms And Condition"],

  FooterServices: ["INTENSHIP", "VOLUNTEERING", "CONSULTING", "PATENTING", "FUNDING", "MENTORING", "TRAINING", "START UP", "RESEARCH"],

  ServicesGrid: [
    {
      title: "INTERNSHIP",
      description:
        "Internships aligned with your interests, fitting your schedule. We monitor your progress for consistency and ensure you're learning steadily. Join us to gain valuable experience at your own pace, tailored to your needs.",
      imgSrc: Images.Internship,
      link: "/pages/internship",
    },
    {
      title: "MENTORING",
      description:
        "Kalam Dream Labs Mentoring for personal support: career advice, skill coaching, project help, or just a friend. Diverse mentors offer real-world wisdom, flexible options, and a supportive community. Unleash your potential with us.",
      imgSrc: Images.Mentoring,
      link: "/pages/mentoring",
    },
    {
      title: "VOLUNTEERING",
      description:
        "Volunteer with us! Impact causes you care about, discover new passions, and join a vibrant community. Gain experience, build skills, and experience the joy of giving back. No experience needed! Find your purpose, unleash your power, and register today.",
      imgSrc: Images.Volunteering,
      link: "/pages/volunteering",
    },
    {
      title: "TRAINING",
      description:
        "Kalam Dream Labs Training: Hands-on tech skills, expert guidance, real-world projects. Collaborative workshops, flexible options. Call us today to know more and apply! Invest in yourself, invest in your future.",
      imgSrc: Images.Training,
      link: "/pages/training",
    },
    {
      title: "CONSULTING",
      description:
        "Kalam Dream Labs Consulting: Co-creating transformative solutions with diverse experts. Human-centered approach. Non-profit. Innovation strategy, capacity building, impactful programs. Together, we ignite progress.",
      imgSrc: Images.Consulting,
      link: "/pages/consulting",
    },
    {
      title: "START UP",
      description:
        "Kalam Dream Labs! Expert mentors, tailored support, skill development. Join our community, network, access investors. From ideas to challenges, we're your startup partner. Contact us to launch together!",
      imgSrc: Images.StartUp,
      link: "/pages/start-up",
    },
    {
      title: "PATENTING",
      description:
        "Kalam Dream Labs – your patent powerhouse! Our expert team offers tailored guidance through the patenting process at student-friendly rates. Gain skills, connect with a supportive community, and safeguard your ideas.",
      imgSrc: Images.Patenting,
      link: "/pages/patenting",
    },
    {
      title: "RESEARCH",
      description:
        "Join us to navigate the research jungle with expert guides. Learn new skills, contribute to projects, find answers. Facing a thesis or need a confidence boost? We're here for you. Join the pack, uncover knowledge together!",
      imgSrc: Images.Research,
      link: "/pages/research",
    },
    {
      title: "FUNDING",
      description:
        "Kalam Dream Labs. Secure funding with expert mentorship, diverse options, tailored preparation. From early-stage to growing startups, get help with proposals, post-funding support. Join us, access investor network.",
      imgSrc: Images.Funding,
      link: "/pages/funding",
    },
  ],

  HowItWorks: [
    {
      title: 'REGISTRATION',
      description:
        'Prospective interns begin by registering through the Kalam Dream Labs website. This simple process involves providing basic personal information and uploading a resume.',
    },
    {
      title: 'INTERVIEW',
      description:
        'Once registered, candidates are contacted for an interview. The interview helps us assess the intern’s skills and determine the best fit for projects.',
    },
    {
      title: 'ONBOARDING AND TRAINING',
      description:
        'Interns who pass the interview are onboarded and provided with training to ensure they are ready to work on real-world projects.',
    },
    {
      title: 'INTERNSHIP EXPERIENCE',
      description:
        'Interns work on various projects and gain hands-on experience in their field of study. They collaborate with mentors and industry professionals throughout the internship.',
    },
  ],

  faqs: [
    { faq: 'What types of internships are available at Kalam Dream Labs?', description: 'Kalam Dream Labs offers internships in various fields including engineering, technology, research, marketing, and business development. Specific roles depend on current projects and organizational needs' },
    { faq: 'How can I apply for an internship?', description: "To apply, register on the Kalam Dream Labs website and upload your resume. After registration, you'll be scheduled for a basic interview" },
    { faq: 'Will I work on real projects?', description: "Yes, interns are assigned to real, impactful projects where they can apply their skills and contribute meaningfully to the company." },
    { faq: 'What does the interview process involve?', description: "The interview process is straightforward. It includes a basic interview to understand your mindset, motivations, and how well you align with the values of Kalam Dream Labs." },
    { faq: 'Who can I contact for more information?', description: 'For additional questions or more information about the internship program, you can contact the Kalam Dream Labs at info@kalamdreamlabs.com or visit the "Contact Us" section on our website.' },
  ],

  InternshipDetails: ` Internships at Kalam Dream Labs cover a wide range of the latest industry-specific subjects. These include Digital Marketing, Web Development, Artificial Intelligence (AI), Machine Learning (ML), Data Science, Graphic Design, Cloud Computing, App Development, DevOps, Video Editing, HR Analytics, and Business Analytics. You can apply for an internship in your field of interest as a student. However, there is a selection process that you need to go through to join the program. Once you are selected, you will be placed in a cohort with other students who share your interests. This setup helps you learn faster and more effectively in your chosen subject. After completing your internship, you get a chance to work on live projects or receive assistance with your interviews for placements. By participating in an internship at Kalam Dream Labs, you can gain practical experience and deepen your knowledge in your specific area of interest. This is a great opportunity to enhance your skills and prepare for your future career.`,
  InternshipRegister:`  Kalam Dream Labs offers enriching internship opportunities designed to provide hands-on experience in cutting-edge projects, fostering innovation and professional growth. Interns collaborate with industry experts, gaining valuable skills and insights to drive their careers forward.`,

  VisionDescription: `
  To become a transformative platform that empowers passionate
  individuals by connecting global technology with local needs,
  creating new opportunities, and nurturing talent. At Kalam Dream
  Labs, we believe in the power of dreams to drive innovation and
  change. Our vision is to unite diverse talents and resources to
  build a supportive community where dreams become actionable goals,
  and together, we can make a meaningful impact on society.
`,

  MissionDescription: `
  At Kalam Dream Labs, our mission is to empower passionate dreamers
  through comprehensive development opportunities. We strive to ignite
  innovation and growth by offering training, mentoring, volunteering,
  startup support, patenting assistance, funding, research, consultancy,
  and internships. Our goal is to create an environment where aspiring
  individuals can transform their dreams into reality, making a profound
  impact on their communities and beyond.
`
};

export default Data;
