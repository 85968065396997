// utils/menuTransform.js
export const transformMenuData = (data) => {
  const menuMap = {};

  // Create a map for each item and initialize subPages as an empty array
  data.forEach((item) => {
    menuMap[item.id] = { ...item, subPages: [] };
  });

  // Populate subPages based on parent-child relationship
  data.forEach((item) => {
    if (item.parent !== "root" && menuMap[item.parent]) {
      menuMap[item.parent].subPages.push(menuMap[item.id]);
    }
  });

  // Filter only the root items
  return Object.values(menuMap).filter((item) => item.parent === "root");
};
