import React, { useEffect, useState } from "react";
import InnerLayout from "../../layouts/InnerLayout";
import { Box, Container, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEventsContext } from "../../appSettings/event/eventProvider.js"; // Use the events context
import { IMAGE_EVENTS_URL, images } from "../../appSettings/imageConstants.js";
import htmlConvert from "../../appSettings/htmlConvert.js";
import Grid from "@mui/material/Grid2";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RoomIcon from '@mui/icons-material/Room';
import WifiIcon from '@mui/icons-material/Wifi';

function EventDetails() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { events } = useEventsContext(); // Use the context to get the events data
  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (events && events.length > 0) {
      const selectedEvent = events.find((eventItem) => eventItem.slug === slug);
      setEvent(selectedEvent);
    }
  }, [events, slug]);

  if (!event) return <div>Loading event details...</div>;

  const eventDescription = event.long_description || ''; // Default to empty string if description is null/undefined

  return (
    <>
      <Helmet>
        <title>{event.name} - Event Details | MyWebsite</title>
        <meta name="description" content={event.short_description} />
        <meta name="keywords" content="" />
      </Helmet>
      <InnerLayout>
        <Box component="section" sx={{ padding: "2rem 0" }}>
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  sx={{ width: "100%", height: "auto", marginBottom: "1rem" }}
                  src={event.event_banner ? `${IMAGE_EVENTS_URL}${event.event_banner}` : images.NoImage}
                  alt={event.name}
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="h2" component="h2" gutterBottom>
                  {event.name}
                </Typography>
                <Typography className='mute-text'><EventIcon fontSize="small" /> Start Date: {event.start_date_and_time}</Typography>
                <Typography className='mute-text'><AccessTimeIcon fontSize="small" /> End Date: {event.end_date_and_time}</Typography>
                <Typography className='mute-text'><RoomIcon fontSize="small" /> Location: {event.Location}</Typography>
                <Typography className='mute-text'><WifiIcon fontSize="small" /> Mode: {event.type}</Typography>
                {event.registration_button === 1 && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate(`/register/${event.slug}`)}
                  >
                    Register
                  </Button>
                )}

                <Typography variant="body2" color="textSecondary">
                  {event.short_description && (
                    <Box
                      component="div"
                      dangerouslySetInnerHTML={{ __html: event.short_description }}
                    />
                  )}
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 12 }}>
                {htmlConvert({ htmlContent: eventDescription })}
              </Grid> 
            </Grid>
          </Container>
        </Box>
      </InnerLayout>
    </>
  );
}

export default EventDetails;
