import React, { createContext, useContext, useState, useEffect } from 'react';
import { getClubs } from '../api/apiService'; // Adjust path as needed

const ClubsContext = createContext();

export const useClubsContext = () => useContext(ClubsContext);

export const ClubsProvider = ({ children }) => {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if clubs data is already in localStorage
    const storedClubs = localStorage.getItem('clubsData');
    
    if (storedClubs) {
      setClubs(JSON.parse(storedClubs)); // Load data from localStorage
      setLoading(false); // Skip loading state if data is available in cache
    } else {
      const fetchClubsData = async () => {
        try {
          const data = await getClubs();
          setClubs(data || []);
          localStorage.setItem('clubsData', JSON.stringify(data)); // Store data in localStorage
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchClubsData();
    }
  }, []);

  return (
    <ClubsContext.Provider value={{ clubs, loading, error }}>
      {children}
    </ClubsContext.Provider>
  );
};
