import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RootProvider from './appSettings/roots/rootProvider.js';
import routes from './appSettings/roots/routs.js';

function App() {
  return (
    <RootProvider>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </BrowserRouter>
    </RootProvider>
  );
}

export default App;
