import React from "react";
import { useParams } from "react-router-dom";
import { useServicesContext } from "../../appSettings/service/serviceProvider.js"; // Use the context to access the services
import InnerBanner from "../../components/innerBanner/innerBanner.js";
import InnerLayout from "../../layouts/InnerLayout.js";
import { Box, Container } from "@mui/material";
import htmlConvert from "../../appSettings/htmlConvert.js"; // Import htmlConvert function
import InnerBannerImage from "../../images/inner-banner.png";
import ServiceAccordion from "./serviceAccordion.js";

function ServiceDetails() {
  const { slug } = useParams(); // Get the slug from the URL params
  const { service, loading, error } = useServicesContext(); // Use context to get the service data

  // Handle loading and error states
  if (loading) return <div>Loading service details...</div>;
  if (error) return <div>{error}</div>;

  // Find the specific service based on the slug
  const ourService = service.find((item) => item.slug === slug);

  // If no service is found with the given slug, show an error
  if (!ourService) return <div>Service not found.</div>;

  return (
    <InnerLayout>
      <InnerBanner
        bannerImage={InnerBannerImage}
        title={ourService.title} // Display the service title here
        tagline={ourService.short_description || "Service Details"}
      />
      <Box component="section" py={4}>
        <Container maxWidth="xl">
          {/* Use htmlConvert to render HTML content */}
          {htmlConvert({ htmlContent: ourService.description })}
        </Container>
      </Box>
      <ServiceAccordion service={ourService} /> {/* Pass service details to accordion */}
    </InnerLayout>
  );
}

export default ServiceDetails;
