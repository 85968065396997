import { useState, useEffect } from 'react';
import { getContact } from '../../appSettings/api/apiService';

const useContact = () => {
  const [contact, setContact] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getContact();
        console.log('API Response:', data); // Log for debugging
        // Ensure `data.contactdetails` is an array
        setContact(Array.isArray(data.contactdetail) ? data.contactdetail : []);
      } catch (err) {
        console.error('Error fetching contact:', err);
        setError('Failed to fetch contact details');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { contact, loading, error };
};

export default useContact;
