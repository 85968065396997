
import React, { useState } from 'react';
import { TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, MenuItem, Checkbox, Button, Typography, Card, CardContent, Container, TextareaAutosize, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import InnerLayout from '../../layouts/InnerLayout';

const ClubRegistration = () => {
  const [formData, setFormData] = useState({
    full_name: '',
    date_of_birth: '',
    gender: '',
    email: '',
    phone_no: '',
    address: '',
    education_details: '',
    college_name: '',
    field_of_study: '',
    company_name: '',
    role: '',
    experience: '',
    level_of_experience: '',
    interest: '',
    additional_info: '',
    additional_preferences: '',
    declaration: false,
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted Form Data:', formData);
  };

  return (
    <InnerLayout>
    <Container maxWidth="xl"  sx={{ marginTop: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Club Registration
      </Typography>

      <form onSubmit={handleSubmit}>
        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <Typography variant="h6">Personal Details</Typography>
            <Grid container spacing={2}>
              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  required
                  label="Full Name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  label="Birth Date and Month"
                  name="date_of_birth"
                  type="date"
                  value={formData.date_of_birth}
                  onChange={handleChange}
                  slotProps={{inputLabel: { shrink: true }}}
                />
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <FormControl>
                  <FormLabel>Gender</FormLabel>
                  <RadioGroup
                    row
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  required
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  required
                  label="Phone No"
                  name="phone_no"
                  value={formData.phone_no}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item size={{xs:12}}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <Typography variant="h6">Academic and Professional Information</Typography>
            <Grid container spacing={2}>
              <Grid item size={{xs:12, md:4}}>
                <FormControl fullWidth>
                  <Select
                    name="education_details"
                    value={formData.education_details}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">Select Highest Qualification</MenuItem>
                    <MenuItem value="high school">High School</MenuItem>
                    <MenuItem value="under graduation">Under Graduation</MenuItem>
                    <MenuItem value="post graduation">Post Graduation</MenuItem>
                    <MenuItem value="masters">Masters</MenuItem>
                    <MenuItem value="phd">PhD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  label="College Name"
                  name="college_name"
                  value={formData.college_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  label="Field of Study"
                  name="field_of_study"
                  value={formData.field_of_study}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  label="Company Name"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item size={{xs:12, md:4}}>
                <TextField
                  fullWidth
                  label="Role"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: 4 }}>
          <CardContent>
            <Typography variant="h6">UAV Program Information</Typography>
            <Grid container spacing={2}>
              <Grid item size={{xs:12, md:6}}>
                <FormControl>
                  <FormLabel>Do you have prior experience with UAV technology?</FormLabel>
                  <RadioGroup
                    row
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item size={{xs:12, md:6}}>
                <Select
                  fullWidth
                  name="level_of_experience"
                  value={formData.level_of_experience}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="">Select Level</MenuItem>
                  <MenuItem value="beginner">Beginner</MenuItem>
                  <MenuItem value="intermediate">Intermediate</MenuItem>
                  <MenuItem value="advanced">Advanced</MenuItem>
                </Select>
              </Grid>

              <Grid item size={{xs:12}}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="What specific aspect of UAV technology interests you?"
                  name="interest"
                  value={formData.interest}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      
        <Card>
        <CardContent>
        <Typography variant="h6">Additional Information</Typography>
        <Grid item size={{xs:12}}>
                <TextField
                  name="achieveFromClub"
                  minRows={3}
                  label="What do you hope to achieve from this Club?"
                  style={{ width: '100%', height:"0px", margin:"20px"}}
                  value={formData.achieveFromClub}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item size={{xs:12}}>
                <TextField
                  name="specialRequirements"
                  minRows={3}
                  label="Any special requirements or preferences?"
                  style={{ width: '100%', height:"50px", margin:"20px" }}
                  value={formData.specialRequirements}
                  onChange={handleChange}
                />
              </Grid>
              <FormControlLabel
          control={
            <Checkbox 
              name="declaration" 
              checked={formData.declaration} 
              onChange={handleChange} 
            />
          }
          label="I am seriously interested in joining the club and learning more about UAV technologies."
        />
        </CardContent>
        </Card>
        <Stack sx={{ display: 'center', justifyContent: 'center', margin: '20px 0', width: "100px" }}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Stack> 
      </form>
    </Container>
    </InnerLayout>
  );
};

export default ClubRegistration;
