import React from 'react';
import { Card, CardContent, Stack, Box, Typography, Container } from '@mui/material';
import Images from '../../../images/images.js';
import Data from '../../../data/data.js';
import '../visionAndMission/visionmissioncard.css'

const VisionMissionCard = () => {
  const visionDescription = Data.VisionDescription;
  const missionDescription = Data.MissionDescription;

  return (
    <Box className="vision-mission-section">
        <Container maxWidth="xl">
        <Box className="vision-mission-heading">
          <Typography variant="h4" component="h2">
            Vision & Mission
          </Typography>
        </Box>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
          {/* Vision Card */}
          <Card className="vision-mission-card">
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center" className="vm-card">
                <Box sx={{ width: { xl: '110%', md: '20%' } }}>
                  <img src={Images.Vision} alt="vision img" className="vision-mission-image" />
                </Box>
                <Box className="vision-mission-content">
                  <Typography variant="h5" color="#2c52a3">
                    Vision
                  </Typography>
                  <Typography variant="body1">{visionDescription}</Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>

          {/* Mission Card */}
          <Card className="vision-mission-card">
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center" className="vm-card">
                <Box sx={{ width: { xl: '110%', md: '20%' } }}>
                  <img src={Images.Mission} alt="mission img" className="vision-mission-image" />
                </Box>
                <Box className="vision-mission-content">
                  <Typography variant="h5" color="#2C52A3">
                    Mission
                  </Typography>
                  <Typography variant="body1">{missionDescription}</Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </Box>
  );
};

export default VisionMissionCard;
