import React from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import { IMAGE_SLIDER_URL, images } from '../../../appSettings/imageConstants.js'; 
import { useSliderContext } from '../../../appSettings/slider/sliderProvider.js'; // Use the context

export default function HeroSlider() {
  const navigate = useNavigate(); 
  const { slider, loading, error } = useSliderContext(); // Use the context to get the slider data

  if (loading) return <div>Loading the slider...</div>;
  if (error) return <div>{error}</div>;

  // Slider settings for react-slick
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {slider.map((item, index) => (
        <Box key={index}>
          <Box
            component="img"
            sx={{
              width: "100%",
              marginRight: "10px",
            }}
            src={item.image ? `${IMAGE_SLIDER_URL}${item.image}` : images.NoImage} 
            alt={item.title}
          />
        </Box>
      ))}
    </Slider>
  );
}
