import React from "react";
import InnerLayout from "./InnerLayout";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useDataContext } from "../appSettings/cms/cmsProvider.js";
import InnerBanner from "../components/innerBanner/innerBanner.js"; // Import the InnerBanner component
import { IMAGE_BASE_URL, images } from "../appSettings/imageConstants.js";

const DynamicPage = () => {
  const { slug } = useParams();
  const { data, loading, error } = useDataContext();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const item = data?.find((item) => item.slug === slug);
  if (!item) return <p>Item not found!</p>;

  // Construct the full image path for banner_image
  const bannerImage = item.banner_image 
    ? `${IMAGE_BASE_URL}${item.banner_image}` 
    : images.InnerBanner;

  return (
    <>
      <Helmet>
        <title>{item.seo_title || "KDl"}</title>
        <meta name="description" content={item.seo_description} />
        <meta name="keywords" content={item.seo_keywords} />
      </Helmet>
      <InnerLayout>
        <InnerBanner 
          bannerImage={bannerImage}
          title={item.banner_title || item.title}
          tagline={item.banner_tagline}
        />
        <Box component="section">
        <Container maxWidth="xl">
            <Typography>{item?.short_description}</Typography>
            <Box 
              component="div" 
              
              dangerouslySetInnerHTML={{ __html: item?.content}} 
            />
          </Container>
        </Box>
      </InnerLayout>
    </>
  );
};

export default DynamicPage;
