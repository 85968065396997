import React, { createContext, useContext, useState, useEffect } from 'react';
import { getServices } from '../api/apiService'; // Ensure this is your API call

const ServicesContext = createContext();

export const useServicesContext = () => useContext(ServicesContext);

export const ServicesProvider = ({ children }) => {
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data only if not already in localStorage
  useEffect(() => {
    const storedServices = localStorage.getItem('servicesData');
    if (storedServices) {
      setService(JSON.parse(storedServices));
      setLoading(false); // If data is already in localStorage, skip loading
    } else {
      const fetchServicesData = async () => {
        try {
          const data = await getServices();
          setService(data || []);
          localStorage.setItem('servicesData', JSON.stringify(data)); // Save to localStorage
        } catch (err) {
          setError('Failed to fetch services');
        } finally {
          setLoading(false);
        }
      };
      fetchServicesData();
    }
  }, []);

  return (
    <ServicesContext.Provider value={{ service, loading, error }}>
      {children}
    </ServicesContext.Provider>
  );
};
