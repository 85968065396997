import React from 'react';
import {
  LinkedIn,
  YouTube,
  Facebook,
  Instagram,
  TripOrigin, 
} from '@mui/icons-material';
import { IconButton, CircularProgress, Box, Alert } from '@mui/material';
import useSocial from "../social/socialApi";



const Social = () => {
  const { social, loading, error } = useSocial();

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const iconMap = {
    "linkedIn-url": LinkedIn,
    "youtube-url": YouTube,
    "facebook-url": Facebook, 
    "instagram-url": Instagram, 
    "tripadvisor-url": TripOrigin, 
  };

  return (
    <Box className="footer-social-icons">
      {social.map(({ slug, value }) => {
        if (!value || !iconMap[slug]) return null;

        const IconComponent = iconMap[slug];
        return (
          <IconButton key={slug} component="a" href={value} target="_blank" rel="noopener noreferrer">
            <IconComponent />
          </IconButton>
        );
      })}
    </Box>
  );
};

export default Social;
