import React from 'react'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'


function InnerLayout({ children }) { // Destructure children from props
  return (
    <>
      <Header/>
   
      {children} {/* Render children */}
  
   
      <Footer/>
    </>
  )
}

export default InnerLayout