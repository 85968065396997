import React from 'react';
import InnerLayout from '../../layouts/InnerLayout';
import Images from '../../images/images';
import { Button, Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function SuccessPage() {
  return (
    <InnerLayout>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
          textAlign="center"
          gap={3}
        >
          <Typography variant="h4" color="primary" gutterBottom>
            Registration Successful!
          </Typography>
          <img
            src={Images.RegistrationSuccess.default}
            alt="Success"
            style={{ width: '80%', maxWidth: 150, margin: '20px 0' }}
          />
          <Typography variant="body1" color="textSecondary" gutterBottom>
          Thank you! Your details have been successfully submitted.

Please check your WhatsApp for all updates related to your interview. We'll keep you informed every step of the way.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              paddingX: 3,
            }}
          >
            Back to Home
          </Button>
        </Box>
      </Container>
    </InnerLayout>
  );
}

export default SuccessPage;
