import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
  Divider,
} from "@mui/material";
import InnerLayout from "../../layouts/InnerLayout";

function LoginSignup() {
  const [loginMobile, setLoginMobile] = useState("");
  const [showLoginOtp, setShowLoginOtp] = useState(false);
  const [loginError, setLoginError] = useState(false);

  // State for registration form

  const [showRegisterOtp, setShowRegisterOtp] = useState(false);
  const [registerErrors, setRegisterErrors] = useState({});
  const [registerFields, setRegisterFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });

  // Login OTP handler
  const handleLoginOtp = () => {
    if (loginMobile) {
      setLoginError(false);
      setShowLoginOtp(true);
    } else {
      setLoginError(true);
    }
  };

  // Register OTP handler with individual field validation
  const handleRegisterOtp = () => {
    let errors = {};

    if (!registerFields.firstName)
      errors.firstName = "Please enter your first name";
    if (!registerFields.lastName)
      errors.lastName = "Please enter your last name";
    if (!registerFields.email) errors.email = "Please enter your email";
    if (!registerFields.mobile)
      errors.mobile = "Please enter your mobile number";

    if (Object.keys(errors).length === 0) {
      setShowRegisterOtp(true);
      setRegisterErrors({});
    } else {
      setRegisterErrors(errors);
    }
  };

  // Handle change for registration form inputs
  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterFields((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <InnerLayout>
         <Container maxWidth="xl">
        <Box sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
          <Grid container spacing={2}>
            {/* Login Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ padding: 3, borderRight: 1, borderColor: "divider" }}
            >
              <Typography variant="h4" gutterBottom>
                Login
              </Typography>
              <Divider sx={{ mb: 3 }} />

              {/* Login Error Message */}
              {loginError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Please enter your mobile number
                </Alert>
              )}

              <form>
                {/* Mobile Input */}
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <TextField
                      id="login_phone"
                      label="Mobile Number"
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="XXXX XXX XXX"
                      value={loginMobile}
                      onChange={(e) => setLoginMobile(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleLoginOtp}
                    >
                      GET OTP
                    </Button>
                  </Grid>
                </Grid>

                {/* OTP Input */}
                {showLoginOtp && (
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      id="login_otp"
                      label="Enter OTP"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{ maxLength: 6 }}
                    />
                  </Box>
                )}

                {/* Login Button */}
                {showLoginOtp && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    type="submit"
                  >
                    Login
                  </Button>
                )}
              </form>
            </Grid>

            {/* Register Section */}
            <Grid item xs={12} md={6} sx={{ padding: 3 }}>
              <Typography variant="h4" gutterBottom>
                Register
              </Typography>
              <Divider sx={{ mb: 3 }} />

              <form>
                <Grid container spacing={2}>
                  {/* First Name */}
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      required
                      value={registerFields.firstName}
                      onChange={handleRegisterChange}
                      error={!!registerErrors.firstName}
                      helperText={registerErrors.firstName}
                    />
                  </Grid>

                  {/* Last Name */}
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      required
                      value={registerFields.lastName}
                      onChange={handleRegisterChange}
                      error={!!registerErrors.lastName}
                      helperText={registerErrors.lastName}
                    />
                  </Grid>
                </Grid>

                {/* Email Input */}
                <Box sx={{ mt: 2 }}>
                  <TextField
                    name="email"
                    label="Email ID"
                    variant="outlined"
                    fullWidth
                    required
                    value={registerFields.email}
                    onChange={handleRegisterChange}
                    error={!!registerErrors.email}
                    helperText={registerErrors.email}
                  />
                </Box>

                {/* Mobile Input */}
                <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
                  <Grid item xs>
                    <TextField
                      name="mobile"
                      label="Mobile Number"
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="XXXX XXX XXX"
                      value={registerFields.mobile}
                      onChange={handleRegisterChange}
                      error={!!registerErrors.mobile}
                      helperText={registerErrors.mobile}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleRegisterOtp}
                    >
                      GET OTP
                    </Button>
                  </Grid>
                </Grid>

                {/* OTP Input */}
                {showRegisterOtp && (
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      id="register_otp"
                      label="Enter OTP"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{ maxLength: 6 }}
                    />
                  </Box>
                )}

                {/* Register Button */}
                {showRegisterOtp && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    type="submit"
                  >
                    Register
                  </Button>
                )}
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </InnerLayout>
  );
}

export default LoginSignup;
