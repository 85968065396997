import React, { useState } from "react";
import {
    Box,
    Button,
    Typography,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Divider,
 
    Checkbox,
    FormLabel,
    Container,
    Card,
    CardContent,
  
} from "@mui/material";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Grid from '@mui/material/Grid2';

import InnerLayout from "../../layouts/InnerLayout";


import './editprofile.css'

const EditProfile = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [profileImage, setProfileImage] = useState("/assets/img/pf1.png");

    const readURL = (input) => {
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileImage(e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    };

    const handleNext = () => {
        if (currentStep < 5) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleStepClick = (step) => {
        setCurrentStep(step);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted");
    };
    

    return (
        <InnerLayout>

<Box component="section">
<Container maxWidth="xl">

<Grid container spacing={2}>
        <Grid size={3}>
    {/* Sidebar */}
    <Card >

        <Box className="edit-menu">
                    {[
                        "Personal Information", 
                        "Educational Information", 
                        "Internship Details", 
                        "Skills And Experience", 
                        "Additional Information"
                    ].map((title, index) => (
                        <Box
                            key={index}
                            className={`profile-list ${currentStep === index + 1 ? "active" : ""}`}
                            display="flex"
                            alignItems="center"
                            mb={2}
                            sx={{
                                cursor: "pointer",
                                color: currentStep === index + 1 ? "primary.main" : "text.secondary",
                            }}
                            onClick={() => handleStepClick(index + 1)}
                        >
                            <Typography variant="body1">
                                {title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            
            </Card>
        </Grid>

        <Grid size={9}>
     {/* Form */}
     <form  id="wizard">
                <Box display="flex" flexDirection="column" gap={4}>
                    {/* Step 1: Personal Information */}
                    {currentStep === 1 && (
                        <Box className="wizard-step" sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
                            <Typography variant="h6" textAlign="left" mb={2}>
                                Personal Information
                            </Typography>
                            <Divider sx={{mb:4}}  />

                            {/* Image Upload */}
                            <Box mb={2} textAlign="center">
                                <label htmlFor="files" style={{ cursor: "pointer", color: "blue" }}>
                                    Upload Image
                                </label>
                                <input id="files" type="file" style={{ display: "none" }} onChange={(e) => readURL(e.target)} />
                                <Box mt={2}>
                                    <img
                                        id="profile-image"
                                        src={profileImage}
                                        alt="Profile"
                                        style={{ width: "160px", height: "160px", borderRadius: "50%", objectFit: "cover" }}
                                    />
                                </Box>
                            </Box>

                            {/* Form Fields */}
                            <Grid container spacing={2}>
                                {/* Name Inputs */}
                                <Grid item size={{ xs: 12, md: 6 }} >
                                    <TextField label="First Name" variant="outlined" fullWidth />
                                </Grid>
                                {/*Last Name Inputs */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField label="Last Name" variant="outlined" fullWidth />
                                </Grid>

                                {/* Email Inputs */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField label="Email" type="email" variant="outlined" fullWidth />
                                </Grid>
                                 {/*  Phone Inputs */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Phone Number"
                                        type="tel"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ pattern: "[0-9]*", maxLength: 10 }}
                                    />
                                </Grid>


                                    {/* Date of Birth Input */}
                                    <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Date of Birth"
                                        type="date"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                {/* Gender Input */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl component="fieldset" fullWidth margin="normal">
                                        <FormLabel component="legend">Gender</FormLabel>
                                        <RadioGroup row>
                                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                            <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                            <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                            

                                 {/* Address Fields */}
                                <Grid item size={{ xs: 12 }}  > <Typography variant="h6" textAlign="left" fullWidth >
                                    Address
                                </Typography>
                                <Divider sx={{mb:4, mt:3}}  />
                                </Grid>

                               
                                  {/*Country  Input*/}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Country</InputLabel>
                                        <Select label="Country">
                                            <MenuItem value="Country1">Country 1</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>

                                 {/*State  Input*/}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>State</InputLabel>
                                        <Select label="State">
                                            <MenuItem value="State1">State 1</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                  {/*State  Input*/}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>District</InputLabel>
                                        <Select label="District">
                                            <MenuItem value="District1">District 1</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                  {/*State  Input*/}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>City</InputLabel>
                                        <Select label="City">
                                            <MenuItem value="City1">City 1</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                  {/*State  Input*/}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField             fullWidth label="House/Flat/Block No." variant="outlined" />
                                </Grid>

                                  {/*State  Input*/}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField             fullWidth label="Zip Code" variant="outlined" />
                                </Grid>



                                {/* Hobbies and Interests */}
                                <Grid item size={{ xs: 12, md: 6 }} >
                                    <Typography variant="subtitle1" fullWidth>Hobbies</Typography>
                                    <FormControlLabel control={<Checkbox />} label="Hobby 1" />
                                   
                                </Grid>
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <Typography variant="subtitle1">Interests</Typography>
                                    <FormControlLabel control={<Checkbox />} label="Interest 1" />
                                   
                                </Grid>

                                {/* Blood Group */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal" >
                                        <InputLabel>Blood Group</InputLabel>
                                        <Select label="Blood Group">
                                            <MenuItem value="A+">A+</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Other Fields */}

                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>How did you hear about us?</InputLabel>
                                        <Select label="How did you hear about us?">
                                            <MenuItem value="Option1">Option 1</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item size={{ xs: 12 }} fullWidth margin="normal">
                                    <TextField label="What's Your Passion" variant="outlined" fullWidth margin="normal" />
                                </Grid>
                                <Grid item size={{ xs: 12, }}>
                                    <TextField             fullWidth label="Referred By" variant="outlined" />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    {/* Step 2: Educational Details */}
                    {currentStep === 2 && (
                        <Box className="wizard-step" sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
                            <Typography variant="h6" textAlign="center" mb={2}>
                                Educational Details
                            </Typography>

                            <Grid container spacing={2}>
                                {/* Program */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Program</InputLabel>
                                        <Select label="Program">
                                            <MenuItem value="Web Development">Web Development</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* University/College Name */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="University/ College Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                {/* Branch */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Branch</InputLabel>
                                        <Select label="Branch">
                                            <MenuItem value="Computer Science">Computer Science</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Recent Semester CGPA */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Recent Semester CGPA"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        inputProps={{ min: "0", max: "10", step: "0.01" }}
                                    />
                                </Grid>

                                {/* Starting Year */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Starting Year"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}

                                        margin="normal"
                                    />
                                </Grid>

                                {/* Last Year */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Last Year"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}

                                        margin="normal"
                                    />
                                </Grid>



                                {/* Academic Achievements */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Mention Your Academic Achievements"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                {/* Upload Academic Achievements and Certificates */}



                                <Grid item size={{ xs: 12, md: 6 }}>
                                   
                                    <Typography variant="subtitle1" gutterBottom>
                                        Academic Achievements and Certificates
                                    </Typography>

                                
                                    <Button
                                        component="label"
                                        variant="contained"
                                        startIcon={<CloudUploadIcon />}
                                        
                                    >
                                        Upload
                                        <input type="file" hidden />
                                    </Button>
                                </Grid>


                                {/* Extra-Curricular Achievements */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Mention Your Extra-Curricular Achievements"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                {/* Upload Extra-Curricular Achievements */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                   
                                   <Typography variant="subtitle1" gutterBottom>
                                    Your Extra-Curricular Achievements
                                   </Typography>

                                   {/* Upload Button */}
                                   <Button
                                       component="label"
                                       variant="contained"
                                       startIcon={<CloudUploadIcon />}
                                       
                                   >
                                       Upload
                                       <input type="file" hidden />
                                   </Button>
                               </Grid>
                                
                            </Grid>
                        </Box>
                    )}


                    {/* Step 3: Internship Details */}
                    {currentStep === 3 && (
                        <Box className="wizard-step" sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
                            <Typography variant="h6" textAlign="center" fullWidth margin="normal">
                                Internship Details
                            </Typography>

                              {/* Present progress and activities */}



                        </Box>
                    )}
                    {/* Step 4: Skills and Experience */}
                    {currentStep === 4 && (
                        <Box className="wizard-step" sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
                            <Typography variant="h6" textAlign="center" mb={2}>
                                Skills And Experience
                            </Typography>

                            <Grid container spacing={2}>
                                {/* Technical Skills */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <Box>
                                        <Typography variant="subtitle1" mb={1}>
                                            Technical Skills
                                        </Typography>
                                        <FormControlLabel control={<Checkbox />} label="Vue JS" />
                                        <FormControlLabel control={<Checkbox />} label="React JS" />
                                        <FormControlLabel control={<Checkbox />} label="Angular" />
                                        <FormControlLabel control={<Checkbox />} label="Node.js" />
                                    </Box>
                                </Grid>

                                {/* Soft Skills */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <Box>
                                        <Typography variant="subtitle1" mb={1}>
                                            Soft Skills
                                        </Typography>
                                        <FormControlLabel control={<Checkbox />} label="Communication" />
                                        <FormControlLabel control={<Checkbox />} label="Teamwork" />
                                        <FormControlLabel control={<Checkbox />} label="Problem-Solving" />
                                        <FormControlLabel control={<Checkbox />} label="Time Management" />
                                    </Box>
                                </Grid>

                                {/* Company Name */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Company Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                {/* Internship Domain */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Internship Domain"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                {/* Internship Duration */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Internship Duration</InputLabel>
                                        <Select label="Internship Duration">
                                            <MenuItem value="3 Months">3 Months</MenuItem>
                                            <MenuItem value="6 Months">6 Months</MenuItem>
                                            <MenuItem value="1 Year">1 Year</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                {/* Internship Year */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Internship Year"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                {/* Major/ Minor project Title */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Major/ Minor project Title"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>


                                {/* GitHub Repository Link */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="GitHub Repository Link"
                                        type="url"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {/* Step 5: Additional Details */}
                    {currentStep === 5 && (
                        <Box className="wizard-step" sx={{ p: 3, borderRadius: 2, boxShadow: 2 }}>
                            <Typography variant="h6" textAlign="center" mb={2}>
                                Additional Details
                            </Typography>

                            <Grid container spacing={2}>
                                {/* Upload Resume Button */}
                                <Grid item size={{ xs: 12 }} >
                                    <Button
                                        component="label"
                                        variant="contained"
                                        startIcon={<CloudUploadIcon />}

                                    >
                                        Upload Resume
                                        <input type="file" hidden />
                                    </Button>
                                </Grid>

                                {/* LinkedIn Input */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="LinkedIn (Optional)"
                                        type="url"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                                {/* Additional Links */}
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="GitHub (Optional)"
                                        type="url"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Personal Website (Optional)"
                                        type="url"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item size={{ xs: 12, md: 6 }}>
                                    <TextField
                                        label="Portfolio Link (Optional)"
                                        type="url"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    {/* Navigation Buttons */}
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        {currentStep > 1 && (
                            <Button variant="outlined" color="primary" onClick={handleBack}>
                                Back
                            </Button>
                        )}
                        {currentStep < 5 ? (
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                Next
                            </Button>
                        ) : (
                            <Button variant="contained" color="success" onClick={handleSubmit}>
                                Submit
                            </Button>
                        )}
                    </Box>
                </Box>
            </form>
        </Grid>
     
        </Grid>
    </Container>
</Box>



    
        </InnerLayout>
    );
};

export default EditProfile;
