import React, { useEffect, useState } from "react";
import InnerLayout from "../../layouts/InnerLayout";
import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import useMentors from "./mentorshook.js"; // Hook or function to fetch mentor data
import { IMAGE_TEAMS_URL, images } from "../../appSettings/imageConstants.js";
import Grid from "@mui/material/Grid2";

function MentorsDetails() {
  const { slug } = useParams(); // Get the mentor slug from the URL
  const { mentors } = useMentors(); // Fetch all mentors initially
  const [mentor, setMentor] = useState(null);

  // Find the mentor by slug after fetching
  useEffect(() => {
    if (mentors && mentors.mentors_all) {
      const selectedMentor = mentors.mentors_all.find(
        (mentorItem) => mentorItem.slug === slug
      );
      setMentor(selectedMentor);
    }
  }, [mentors, slug]);

  if (!mentor) return <div>Loading mentor details...</div>;

  return (
    <InnerLayout>
     <Container maxWidth="xl">
        <Box component="section" sx={{ padding: "2rem 0" }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box
                component="img"
                sx={{ width: "100%" }}
                src={
                  mentor.image
                    ? `${IMAGE_TEAMS_URL}${mentor.image}`
                    : images.NoImage
                }
                alt={mentor.name}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                {mentor.name}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{ __html: mentor.description }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </InnerLayout>
  );
}

export default MentorsDetails;
