import React, { useState } from "react";
import { 
  Box, 
  Container,  
  TextField, 
  Button, 
  Typography, 
  TextareaAutosize, 
  Alert, 
  List,
  ListItem
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { LocationOn, Phone, Email } from "@mui/icons-material";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../components/innerBanner/innerBanner";
import InnerBannerImage from '../../images/inner-banner.png'; 
import { Link } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptcha = () => {
    setCaptchaVerified(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (captchaVerified) {
      setFormSubmitted(true);
      // Here, you would make an API call with formData.
      console.log("Form Data Submitted:", formData);
    } else {
      alert("Please verify the captcha.");
    }
  };

  return (
    <InnerLayout>
      <InnerBanner bannerImage={InnerBannerImage} title="Contact" />
      <Box component='section' >
        <Container maxWidth="xl" >
        <Grid container spacing={4}>
          {/* Left Section */}
          <Grid item size={{xs:12, md:6}}>
            <Typography variant="h4" gutterBottom>
              Get In Touch
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item size={{xs:12,sm:6}}>
                  <TextField
                    fullWidth
                    required
                    name="name"
                    label="Name"
                    value={formData.name}
                    onChange={handleChange}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item size={{xs:12, sm:6}}>
                  <TextField
                    fullWidth
                    required
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item size={{xs:12, sm:12}}>
                  <TextField
                    fullWidth
                    required
                    name="phone"
                    label="Phone"
                    type="text"
                    inputProps={{ maxLength: 10, minLength: 10 }}
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item size={{xs:12}}>
                  <TextareaAutosize
                    minRows={5}
                    placeholder="Message (min. 50 characters)"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    style={{ width: "100%", padding: "10px" }}
                    required
                  />
                </Grid>
              </Grid>

              <Box size={{mt:2}}>
                {/* <ReCAPTCHA
                  sitekey="6LcT3OcpAAAAAA9EbzTPOYOKkx-hwHqKJRPpSMUj"
                  onChange={handleCaptcha}
                /> */}
              </Box>

              <Box size={{mt:2}}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // disabled={!captchaVerified}
                >
                  Submit
                </Button>
              </Box>
            </form>

            {formSubmitted && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Thank you for contacting us. We will get back to you soon!
              </Alert>
            )}
          </Grid>

          {/* Right Section */}
          <Grid item size={{xs:12, md:6}}>
            <Typography variant="h4" gutterBottom>
              Contact 
            </Typography>

<List className="icon-list">
  <ListItem>
    <Link to="https://maps.app.goo.gl/zVzDSJ3gaSwfvY8dA" target="_blank"><LocationOn sx={{ mr: 1 }} />
    Flat no. 404, <br/>Vinayagar Heights, Sampath <br/>Vinayaka Temple Road</Link>
  </ListItem>
  <ListItem>
    <Link to="tel:733-733-7778" target="_blank"> <Phone sx={{ mr: 1 }} /> 733-733-7778</Link>
  </ListItem>
  <ListItem>
    <Link to="mailto:info@kalamdreamlabs.com" target="_blank">   <Email sx={{ mr: 1 }} /> info@kalamdreamlabs.com</Link>
  </ListItem>

</List>


         
          </Grid>
        </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
};

export default ContactUs;







{/* <InnerBanner bannerImage={InnerBannerImage} title="Contact" /> */}