import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../testimonials/testimonials.css';
import cloudyImage from '../../../images/cloudy.svg';  // Change here

const TestimonialsSection = () => {
    const testimonials = [
        {
            name: 'Tabia Begum',
            feedback: `KDL provides us with opportunities in different enterprises in addition to internship. I learnt to make better connections. KDL team always encouraged me to voice out my opinions and I gave honest feedback all the time.`,
            videoUrl: 'https://www.youtube.com/embed/QC5NBp9c5nI',
        },
        {
            name: 'Ekshitha',
            feedback: `I got to know about KDL through my senior. KDL gives a good platform. KDL is unique from other platforms as it allows peer-to-peer learning and I got inspired from co-interns as well.`,
            videoUrl: 'https://www.youtube.com/embed/peh5S5-mVBk?si=JeVnAHJGml7Uzw9s',
        },
        {
            name: 'Sai Vivek Bhadragiri',
            feedback: `I came to know KDL through VV Beach clean-up. My journey in KDL has been smooth. The team encourages us to understand and solve real life problems and also gives an exposure to diverse people.`,
            videoUrl: 'https://www.youtube.com/embed/sUUQbEVBNII?si=jbdR2iYlc0xcWTw2',
        },
        {
            name: 'Vamsi Kandula',
            feedback: `KDL offers a various range of domains to choose from. It helped me understand what an industry expects from a fresher.`,
            videoUrl: 'https://www.youtube.com/embed/2nRhBUuylI4',
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <Box className="testimonials-section" component="section">
                <Container maxWidth="xl" sx={{ zIndex: 2, position: 'relative' }}>
                    <Grid container spacing={3}>
                        <Grid item size={{ xs: 12, md: 4 }}>
                            <Box color="white">
                                <Typography variant="h5">
                                    Testimonials
                                </Typography>
                                <Typography variant="h2">
                                    What People<br />Say About Us
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item size={{ xs: 12, md: 8 }}>
                            <Slider {...settings}>
                                {testimonials.map((testimonial, index) => (
                                    <div key={index}>
                                        <Grid container spacing={3} alignItems="center">
                                            <Grid item size={{ xs: 12, md: 6 }}>
                                                <Box sx={{ margin: { xs: '0 auto', sm: '0' }, p: 2 }}>
                                                    <iframe
                                                        width="100%"
                                                        height="350"
                                                        src={testimonial.videoUrl}
                                                        title={`Testimony by ${testimonial.name}`}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowFullScreen
                                                        style={{ borderRadius: '8px' }}
                                                    ></iframe>
                                                </Box>
                                            </Grid>

                                            <Grid item size={{ xs: 12, md: 6 }} color="white">
                                                <Typography variant="body1">
                                                    "{testimonial.feedback}"
                                                </Typography>
                                                <Typography variant="h6">
                                                    {testimonial.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </Slider>
                        </Grid>
                    </Grid>
                </Container>

                {/* Use the imported image here */}
                <Box component="img" src={cloudyImage} className="divider" />
            </Box>
        </>
    );
};

export default TestimonialsSection;
