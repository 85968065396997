import { useState, useEffect } from 'react';
import { getServices } from '../api/apiService'; // Make sure this is your API call

const useServices = () => {
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data only once
  useEffect(() => {
    const fetchData = async () => {
      if (service.length === 0) {  // Only fetch if data isn't cached
        try {
          const data = await getServices();
          setService(data || []);
        } catch (err) {
          setError('Failed to fetch service');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);  // If data is already cached, no need to load again
      }
    };
    fetchData();
  }, [service.length]);  // Dependency on service.length, which ensures fetch is triggered only once

  return { service, loading, error };
};

export default useServices;
