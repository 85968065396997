import React, { createContext, useContext, useState, useEffect } from 'react';
import { getSlider } from '../api/apiService'; // Adjust path as needed

const SliderContext = createContext();

export const useSliderContext = () => useContext(SliderContext);

export const SliderProvider = ({ children }) => {
  const [slider, setSlider] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if slider data is already in localStorage or sessionStorage
    const storedSlider = localStorage.getItem('sliderData');
    
    if (storedSlider) {
      setSlider(JSON.parse(storedSlider)); // Load data from localStorage
      setLoading(false); // Skip loading state if data is available in cache
    } else {
      const fetchSliderData = async () => {
        try {
          const data = await getSlider();
          setSlider(data || []);
          localStorage.setItem('sliderData', JSON.stringify(data)); // Store data in localStorage
        } catch (err) {
          setError('Failed to fetch slider');
        } finally {
          setLoading(false);
        }
      };
      fetchSliderData();
    }
  }, []);

  return (
    <SliderContext.Provider value={{ slider, loading, error }}>
      {children}
    </SliderContext.Provider>
  );
};
